import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/Logo.png";
import First from "../Images/Topc16a.png";
// import Second from "./2.png";
// import Third from "./3.png";
import Footer from "../WhyDubaiComps/Footer";
import PRojectCards from "./PRojectCards";
import useScrollToTop from "../Scroll";
const RakProjectPage = () => {
  useScrollToTop();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <Link to={"/"} className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </Link>
             <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
              <li>
                <Link to="/rental" className="hover:font-bold">
                  RENTAL
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-[#fff] text-black py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
         <Link to={"/"} className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </Link>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
         {menuOpen && (
          <ul className="bg-[#fff] text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav> <section className="w-full sm:w-11/12 mx-auto flex flex-col-reverse sm:flex-row justify-between p-4">
  <div className="bg-[#FAFAFA] px-4 w-full sm:w-2/5 text-2xl shadow-lg flex flex-col justify-center mb-6 sm:mb-0">
    <h1 className="font-bold text-3xl sm:text-4xl mt-4 mb-4">RAS AL KHAIMAH</h1>
    <p className="text-xl sm:text-xl">
    Ras Al Khaimah is a stunning emirate in the UAE, known for its pristine landscapes, serene beaches, and rich cultural heritage. This captivating destination offers an unparalleled blend of natural beauty and luxury living. The luxurious developments in Ras Al Khaimah feature modern, elegant residences.    </p>
  </div>

  <div className="w-full sm:w-3/5 flex justify-center items-center">
    <img src={First} alt="Al Brari Properties" className="max-w-full h-" />
  </div>
</section>

      <div>
        <PRojectCards />
      </div>
      <Footer />
    </div>
  );
};

export default RakProjectPage;
