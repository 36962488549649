import React, { useState } from "react";
import Measure from "../Images/measure.png";
import beds from "../Images/bed.png";
import Bath from "../Images/bath.png";
import Pdf from "../Images/pdf.png";
import Prop1 from "../Images/Prop1.png";
import vector from "../Images/vector.png";
import Prop2 from "../Images/Prop2.png";
import Prop3 from "../Images/Prop3.png";
import Beds from "../Images/beds.png";
import Baths from "../Images/baths.png";
import Menu from "../Images/menu.png";
import Cars from "../Images/car.png";
import Area from "../Images/area.png";
import PDF from "./Zorah.pdf";
import { Link } from "react-router-dom";
import Footer from "../WhyDubaiComps/Footer";
import ContactForm from "../AboutComponents/ContactForm";
const Description = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const handleDownload = async () => {
    const newErrors = {
      name: !formValues.name,
      email: !formValues.email,
      phone: !formValues.phone,
    };
  
    setErrors(newErrors);
  
    if (!newErrors.name && !newErrors.email && !newErrors.phone) {
      const formData = new FormData();
      formData.append("name", formValues.name);
      formData.append("email", formValues.email);
      formData.append("phone", formValues.phone);
      formData.append("message", "Brochure download request");
  
      try {
        const response = await fetch("https://formspree.io/f/xwpkpjea", {
          method: "POST",
          body: formData,
        });
  
        // Log the full response for debugging
        const responseText = await response.text();
        console.log("Response Status:", response.status);
        console.log("Response Text:", responseText);
  
        if (response.ok || response.status === 200) {
          alert("Thank you! Your details have been submitted, and the download will start shortly.");
          const link = document.createElement("a");
          link.href = PDF;
          link.download = "TilalBrochure.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          setFormValues({
            name: "",
            email: "",
            phone: "",
          });
          setShowPopup(false);
        } else {
          alert("Oops! There was an error sending your details. Please try again.");
        }
      } catch (error) {
        console.error("Fetch error: ", error.message);
        alert("Thank you! Your details have been submitted, and the download will start shortly.");
        const link = document.createElement("a");
          link.href = PDF;
          link.download = "TilalBrochure.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          setFormValues({
            name: "",
            email: "",
            phone: "",
          });
          setShowPopup(false);
      }
    }
  };
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const data = [
    { heading: "Category", text: "Residential" },
    { heading: "Built-Up Area", text: "5800 SQFT" },
    { heading: "Types", text: "4-Bedroom Villa" },
    { heading: "Price", text: "Call For Price" },
    { heading: "City", text: "Ajman" },
  ];

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <div className=" w-11/12 mx-auto">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center p-6 space-y-4 sm:space-y-0">
  {/* Title */}
  <h1 className="text-xl sm:text-xl font-bold">Al Zorah</h1>

  {/* Features (Beds, Baths, Area) */}
  <div className="flex flex-col sm:flex-row gap-4">
    <div className="flex items-center border border-[#8E8E8E] text-[#8E8E8E] rounded p-2 w-full sm:w-fit">
      <img src={beds} alt="Beds" className="mr-4" />
      <p className="border-l border-[#8E8E8E] pl-3">4 Beds</p>
    </div>
    <div className="flex items-center border border-[#8E8E8E] text-[#8E8E8E] rounded p-2 w-full sm:w-fit">
      <img src={Bath} alt="Baths" className="mr-4" />
      <p className="border-l border-[#8E8E8E] pl-3">6 Baths</p>
    </div>
    <div className="flex items-center border border-[#8E8E8E] text-[#8E8E8E] rounded p-2 w-full sm:w-fit">
      <img src={Measure} alt="Area" className="mr-4" />
      <p className="border-l border-[#8E8E8E] pl-3">10,892 SQFT</p>
    </div>
  </div>

  {/* Button */}
  <div className="flex justify-start sm:justify-center w-full sm:w-auto">
    <button
      onClick={handleModalOpen}
      className="bg-[#D8B56D] uppercase text-white rounded-full py-2 px-6 w-full sm:w-auto"
    >
      Inquiries
    </button>
  </div>
</div>

      </div>
    {isModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50">
    <div className="relative w-full max-w-lg p-4 md:max-w-md sm:max-w-xs">
      <button
        className="absolute top-4 right-4 text-gray-600 sm:top-2 sm:right-2"
        onClick={() => setIsModalOpen(false)}
      >
        &times;
      </button>
      <div className="bg-white p-6 rounded-lg shadow-lg max-h-[90vh] overflow-auto">
        <ContactForm />
      </div>
    </div>
  </div>
)}
      <div className="flex justify-between items-center my-10  w-11/12 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 w-full ">
          {data.map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-2">{item.heading}</h2>
              <p className="text-gray-700">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div class="w-11/12 mx-auto">
        <div class=" p-6 mb-8">
          <p class="text-gray-700 text-2xl">
            Welcome to the luxurious community of Al Zorah, Ajman, where an
            unparalleled lifestyle and living experience await you amidst the
            serene beauty of nature. This magnificent four-story villa
            epitomizes elegance and comfort, seamlessly blending modern design
            with the tranquility of its surroundings. With spacious interiors
            and exquisite finishes, every corner of this villa is crafted to
            provide a sense of luxury and relaxation. Al Zorah is not just a
            residence; it’s a lifestyle choice that embraces the beauty of
            nature while offering a wealth of high-end services and amenities.
            Experience the perfect balance between comfort and sophistication,
            where each day is a retreat into a world of luxury.
          </p>
        </div>

        <div class="">
          <h3 class="text-2xl font-bold mb-4">Amenities and Lifestyle</h3>
          <ul className="list-disc pl-12 text-[#505050]">
            <li>Elegant design with high-quality finishes</li>
            <li>Private garden and swimming pool</li>
            <li>Ample parking space</li>
            <li>Al Zorah Golf Club (18-hole championship course)</li>
            <li>Beachfront access with pristine sandy beaches</li>
            <li>State-of-the-art marina facilities</li>
            <li>Protected mangroves, lagoons, and walking trails</li>
            <li>Modern fitness centers and yoga studios</li>
            <li>Jogging and cycling tracks</li>
            <li>Upscale shops, cafes, and gourmet restaurants</li>
            <li>Playgrounds and splash parks</li>
            <li>Proximity to top-tier schools and nurseries</li>
            <li>Active living with fitness centers and sports courts</li>
            <li>Relaxation in serene green spaces and parks</li>
            <li>
              Participation in regular community events and cultural festivals
            </li>
            <li>
              Convenient access to major highways, business districts, and
              attractions
            </li>
            <li>
              Vibrant social scene with cafes, restaurants, and community
              gatherings
            </li>
            <li>
              Connection with nature through extensive trails and reserves
            </li>
            <li>
              Family-oriented environment with dedicated play areas and
              educational institutions
            </li>
          </ul>
        </div>
        <div>
          <button
            className="px-6 py-3 flex bg-[#D8B56D] uppercase  text-white rounded-3xl mx-auto my-20 w-96 justify-center"
            onClick={() => setShowPopup(true)}
          >
            Download Brochure
          </button>

         {showPopup && (
  <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg p-6 sm:p-8 md:p-10 w-11/12 sm:w-3/4 md:w-1/3 h-auto sm:h-auto md:h-[90vh] relative">
      {/* Close button */}
      <button
        className="absolute top-4 right-4 text-gray-600 sm:top-2 sm:right-2"
        onClick={() => setShowPopup(false)}
      >
        &times;
      </button>
      <div className="py-8 sm:py-10 flex flex-col justify-between h-full">
        <h2 className="text-3xl sm:text-4xl font-bold mb-4">DOWNLOAD</h2>
        <h3 className="text-3xl sm:text-4xl text-[#000] font-bold mb-6">
          THE BROCHURE
        </h3>
        <p className="mb-6 text-lg">Enter your details to get your copy.</p>

        {/* Form fields */}
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formValues.name}
          onChange={handleChange}
          className={`w-full mb-3 sm:mb-4 p-3 border ${
            errors.name ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.name && (
          <p className="text-red-500 text-sm mb-2">Please fill this field.</p>
        )}

        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formValues.email}
          onChange={handleChange}
          className={`w-full mb-3 sm:mb-4 p-3 border ${
            errors.email ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.email && (
          <p className="text-red-500 text-sm mb-2">Please fill this field.</p>
        )}

        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formValues.phone}
          onChange={handleChange}
          className={`w-full mb-3 sm:mb-4 p-3 border ${
            errors.phone ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.phone && (
          <p className="text-red-500 text-sm mb-2">Please fill this field.</p>
        )}

        {/* Download button */}
        <button
          className="w-full mt-6 bg-[#D8B56D] uppercase text-white p-3 rounded"
          onClick={handleDownload}
        >
          Download
        </button>

        <p className="mt-6 text-lg text-gray-600">
          By submitting this form you agree to subscribe to updates from PointX
          Realty and be sent information in relation to its products and services.
        </p>
      </div>
    </div>
  </div>
)}

        </div>
      </div>
      {/* <h1 className="text-3xl my-10 font-semibold w-11/12 mx-auto">
        Similar Properties
      </h1>
      <div className="w-11/12 mx-auto flex justify-between p-6 gap-6 ">
        <div className=" overflow-hidden shadow-lg border">
          <div className="relative">
            <Link to={"/tilalAlGhaf#top"}>
              <img className="w-full" src={Prop1} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4 bg-black text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className=" pt-4">
            <Link
              to={"/tilalAlGhaf#top"}
              className="font-bold flex justify-center text-xl text-center mb-2"
            >
              Tilal Al Ghaf
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom apartment in Tilal Al Ghaf
            </p>
            <p className=" justify-center flex text-[#969696]">
              {" "}
              <img src={vector} className="mr-3" alt="" />
              Los Angel, CA 90210
            </p>
            <hr className="my-4" />
            <div className="flex px-6 justify-between">
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Menu}
                  alt="Feature 1"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">
                    605 SQ.M/6,515 SQFT
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Beds}
                  alt="Feature 3"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">6 Bedrooms</p>
                </div>
              </div>
            </div>
            <div className="flex px-6 justify-between mt-4">
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Cars}
                  alt="Feature 2"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">3 Garage</p>
                </div>
              </div>
              <div className="flex  items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Baths}
                  alt="Feature 4"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">6 Bathrooms</p>
                </div>
              </div>
            </div>
            <button className="bg-[#D8B56D] text-white uppercasebg-[#D8B56D] uppercase  text-white font-bold py-4 px-4 rounded mt-4 w-full">
              Residential
            </button>
          </div>
        </div>
        <div className=" overflow-hidden shadow-lg border">
          <div className="relative">
            <Link to={"/zorah#top"}>
              <img className="w-full" src={Prop2} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4 bg-black text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className=" pt-4">
            <Link
              to={"/zorah#top"}
              className="font-bold flex justify-center text-xl text-center mb-2"
            >
              Al Zorah
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom apartment in Tilal Al Ghaf
            </p>
            <p className=" justify-center flex text-[#969696]">
              {" "}
              <img src={vector} className="mr-3" alt="" />
              Los Angel, CA 90210
            </p>
            <hr className="my-4" />
            <div className="flex px-6 justify-between">
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Menu}
                  alt="Feature 1"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">
                    605 SQ.M/6,515 SQFT
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Beds}
                  alt="Feature 3"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">6 Bedrooms</p>
                </div>
              </div>
            </div>
            <div className="flex px-6 justify-between mt-4">
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Cars}
                  alt="Feature 2"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">3 Garage</p>
                </div>
              </div>
              <div className="flex  items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Baths}
                  alt="Feature 4"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">6 Bathrooms</p>
                </div>
              </div>
            </div>
            <button className="bg-[#D8B56D] text-white uppercasebg-[#D8B56D] uppercase  text-white font-bold py-4 px-4 rounded mt-4 w-full">
              Residential
            </button>
          </div>
        </div>
        <div className=" overflow-hidden shadow-lg border">
          <div className="relative">
            <Link to={"/farms#top"}>
              <img className="w-full" src={Prop3} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4 bg-black text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className=" pt-4">
            <Link
              to={"/farms#top"}
              className="font-bold flex justify-center text-xl text-center mb-2"
            >
              The Farms
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom apartment in Tilal Al Ghaf
            </p>
            <p className=" justify-center flex text-[#969696]">
              {" "}
              <img src={vector} className="mr-3" alt="" />
              Los Angel, CA 90210
            </p>
            <hr className="my-4" />
            <div className="flex px-6 justify-between">
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Menu}
                  alt="Feature 1"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">
                    605 SQ.M/6,515 SQFT
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Beds}
                  alt="Feature 3"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">6 Bedrooms</p>
                </div>
              </div>
            </div>
            <div className="flex px-6 justify-between mt-4">
              <div className="flex items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Cars}
                  alt="Feature 2"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">3 Garage</p>
                </div>
              </div>
              <div className="flex  items-center">
                <img
                  className=" rounded-full mr-4"
                  src={Baths}
                  alt="Feature 4"
                />
                <div className="text-sm">
                  <p className="text-gray-900 leading-none">6 Bathrooms</p>
                </div>
              </div>
            </div>
            <button className="bg-[#D8B56D] text-white uppercasebg-[#D8B56D] uppercase  text-white font-bold py-4 px-4 rounded mt-4 w-full">
              Residential
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-10">
        <Link
          to={"/offplan#top"}
          type="button"
          class="text-white bg-[#D8B56D]  w-[20%]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Explore all
        </Link>
      </div> */}
       <h1 class="text-2xl ml-20 font-bold mb-4">
        Similar Properties
      </h1>
      <div className="w-11/12 mx-auto flex flex-col md:flex-row  p-6 gap-12">
        {/* Property Card 1 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/tilalAlGhaf#top"}>
              <img className="w-full h-64 object-cover" src={Prop1} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4 bg-black text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/tilalAlGhaf#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Tilal Al Ghaf
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom Villa in Tilal Al Ghaf
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">605 /6,515 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">6 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Cars} alt="Feature 2" />
                <p className="text-sm text-gray-900">3 Garage</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">7 Bathrooms</p>
              </div>
            </div>
            <Link to={"/tilalAlGhaf#top"}>
              <button className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 2 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/zorah#top"}>
              <img className="w-full h-64 object-cover" src={Prop2} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4 bg-black text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/zorah#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Al Zorah
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom villa in Alzorah
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Ajman
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">5800 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">4 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Cars} alt="Feature 2" />
                <p className="text-sm text-gray-900">3 Garage</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">6 Bathrooms</p>
              </div>
            </div>
            <Link to={"/zorah#top"}>
              <button className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 3 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/DownTown#top"}>
              <img className="w-full h-64 object-cover" src={Prop3} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4 bg-black text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/DownTown#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Burj Lake Hotel
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              2 Bedroom Apartment in The Address Downtown 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">120/1,292 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">2 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">1 Bathroom</p>
              </div>
            </div>
            <Link to={"/DownTown#top"}>
              <button className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Description;
