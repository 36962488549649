// Card.js
import React, { useState } from "react";
import Topc0 from "../Images/Topc.png";
import Topc1 from "../Images/Topc1.png";
import Topc2 from "../Images/Topc2.png";
import Topc3 from "../Images/Topc3.png";
import Topc4 from "../Images/Topc4.png";
import Topc5 from "../Images/Topc5.png";
import Topc6 from "../Images/Topc6.png";
import Topc7 from "../Images/Topc7.png";
import Topc8 from "../Images/Topc8.png";
import Topc9 from "../Images/Topc9.png";
import Topc10 from "../Images/Topc10.png";
import Topc11 from "../Images/Topc11.png";
import Topc12 from "../Images/Topc12.png";
import Topc13 from "../Images/Topc13.png";
import Topc14 from "../Images/Topc14.png";
import Topc15 from "../Images/Topc15.png";
import Topc16 from "../Images/Topc16.png";
import NC1 from "../Images/nc1.png";
import Nc2 from "../Images/nc2.png";
import Nc3 from "../Images/nc3.png";
import NC4 from "../Images/nc4.png";
import Nc5 from "../Images/nc5.png";
import Nc6 from "../Images/nc6.png";
import Nc7 from "../Images/nc7.png";
import Nc8 from "../Images/nc8.png";
import Nc9 from "../Images/nc9.png";
import Nc10 from "../Images/nc10.png";
import Nc11 from "../Images/nc11.png";
import { Link } from "react-router-dom";

const Popup = ({ image, title, text, onClose, link }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-4 w-11/12 md:w-2/3 lg:w-1/2">
        <div
          className="bg-cover bg-center rounded-t-lg h-64"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <div className="p-4 text-center">
          <h2 className="text-3xl font-semibold mb-4">{title}</h2>
          <p className="mb-4">{text}</p>
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded"
            >
              Back
            </button>
            {link ? (
              <Link
                to={link}
                className="bg-[#D8B56D] text-white uppercase bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
              >
                View Properties
              </Link>
            ) : (
              <button
                className="bg-gray-300 text-gray-600 cursor-not-allowed py-2 px-4 rounded"
                disabled
              >
                No Link Available
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


const Card = ({ image, title, onViewDetails }) => {
  return (
    <div
      className="bg-transparent shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-4 bg-cover bg-center transform transition duration-500 hover:scale-105"
      style={{
        backgroundImage: `url(${image})`,
        width: "400px",
        height: "265px",
      }}
    >
      <div className="w-[440px] rounded-lg text-center p-4 flex flex-col justify-between h-full">
        <h2 className="text-3xl text-white font-semibold mt-20 mb-1 text-shadow-md animate-fadeIn">
          {title}
        </h2>
        <button
          onClick={onViewDetails}
          className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn"
        >
          View Details
        </button>
      </div>
    </div>
  );
};

const LastCard = ({ image, title, onViewDetails }) => {
  return (
    <div
      className="bg-transparent shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-4 bg-cover bg-center transform transition duration-500 hover:scale-105"
      style={{
        backgroundImage: `url(${image})`,
        width: "800px", // Double the width of a single card
        height: "265px",
      }}
    >
      <div className="w-[840px] rounded-lg text-center p-4 flex flex-col justify-between h-full">
        <h2 className="text-3xl text-white font-semibold mt-20 mb-1 text-shadow-md animate-fadeIn">
          {title}
        </h2>
        <Link to={"/JVC#top"}
          onClick={onViewDetails}
          className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};
const FirstCard = ({ image, title }) => {
  return (
    <div
      className="bg-transparent shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-4 bg-cover bg-center transform transition duration-500 hover:scale-105"
      style={{
        backgroundImage: `url(${image})`,
        width: "800px", // Double the width of a single card
        height: "265px",
      }}
    >
      <div className="w-[840px] rounded-lg text-center p-4 flex flex-col justify-between h-full">
        <h2 className="text-3xl text-white font-semibold mt-20 mb-1 text-shadow-md animate-fadeIn">
          {title}
        </h2>
        <Link to={"/RAK#top"}
          className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

const images = [
  Topc1,
  Topc2,
  Topc3,
  Topc4,
  Topc0,
  Topc5,
  Topc6,
  Topc7,
  Topc8,
  Topc9,
  Topc10,
  Topc11,
  Topc12,
  Topc13,
  Topc14,
  NC1,
Nc2,
Nc3,
NC4,
Nc5,
Nc6,
Nc7,
Nc8,
Nc9,
Nc10,
Nc11,
];

const titles = [
  "DownTown Dubai",
  "Dubai Marina",
  "Jumeirah Village Triangle",
  "Business Bay",
  "Damac Hills",
  "Expo City",
  "Al Barari",
  "Al Furjan",
  "Palm Jumeirah",
  "Dubai Creek Harbour",
  "Duabi Islands ",
  "Production City",
  "Emaar Beachfront",
  "Meydan District One",
  "Tilal Al Ghaf",
  "Dubai Land",
  "Maritime City",
  "Motor city",
  "Sharjah",
  "Dubai Sports City",
  "Al Jaddaf",
  "Studio City",
  "Dubai South",
  "Dubai Health Care City",
  "The Valley",
  "Jabel Ali",
];

const popupTexts = [
  "Downtown Dubai is a bustling multicultural district known for its iconic landmarks such as the Burj Khalifa, Dubai Mall, and Dubai Fountain. It offers a mix of luxury apartments, world-class shopping, dining, and entertainment options.",
  "Dubai Marina is a waterfront community famous for its breathtaking skyline, charming marina, and vibrant way of life. It has upscale eateries, entertainment venues, and waterfront apartments. Living on the waterfront with easy access to the promenade and beach is enjoyed by residents.",
  "A tranquil community offering a mix of villas, townhouses, and apartments. Family-centered JVT turns urban living into pleasant suburban living due to its combination of green spaces and family-oriented environments while providing quick access to Dubai's central regions. Families will find it convenient because JVT contains educational institutions and shopping areas along with places for recreation throughout its neighborhoods.",
  "Business Bay is a thriving business district, Situated next to the Dubai Water Canal, It has luxury hotels, contemporary skyscrapers, and a wide range of eateries and shops. Entrepreneurs and business professionals are drawn to its dynamic atmosphere and strategic location.",
  "Damac Hills is a master-planned golf community offering a range of luxury villas, townhouses, and apartments. It boasts an 18-hole championship golf course, lush green spaces, and family-friendly amenities such as schools, parks, and sports facilities.",
  "The innovative development emerged from the former World Expo grounds in 2020. This sustainable urban development combines futuristic infrastructure with the latest sustainable practices while establishing itself as an active, future-oriented community linked to international connections. Smart city solutions deployed throughout the area bring modern convenience and operational excellence to residents.",
  "Luxury residents can find high-end villas in the greenery of Al Barari as the community focuses on protecting the environment. People who love luxury but want a peaceful garden-filled environment will find Al Barari their perfect setting. The community delights residents with premium dining facilities and health services, which combine to create an inclusive lifestyle experience.",
  "A thriving community with modern townhouses and villas. Al Furjan integrates convenient amenities among its family-centric characteristics to provide a perfect blend of happiness with its suburban-friendly environment. Property owners in this area access a blend of daily convenience and recreational activities through their local facilities, including parks, retail spaces, and fitness centers.",

  "Palm Jumeirah is a world-renowned man-made island offering exclusive waterfront living with breathtaking views of the Arabian Gulf. It features luxury villas, apartments, and hotels, as well as upscale dining, shopping, and entertainment options.",
  "Dubai Creek Harbour is a waterfront development located near Dubai Creek, providing expansive views of Dubai Creek Tower and the city skyline. It creates a lively urban community with luxurious residences, waterfront promenades, and cultural attractions.",
  "An exclusive waterfront development offering upscale living with stunning views of the Arabian Gulf. Dubai Islands creates an avenue for individuals who want seafront peace alongside luxury and world-class facilities for ultimate relaxation. People who reside on these properties benefit from exclusive beachfront access, marina availability, and recreational waterfront resort enjoyment for a life of supreme luxury.",
  "The business hub known previously as IMPZ operates today under the name Production City, which delivers commercial and residential alternatives alongside vibrant engagement. Residents benefit from both professional accessibility and convenience as well as emerging community facilities. Business activities combined with innovation efforts make this region a preferred destination for companies and business owners. ",
  "Emaar Beachfront is a beachfront community offering luxury waterfront living with private beach access and stunning views of the Arabian Gulf. It features upscale residences, hotels, and leisure facilities, creating a resort-style lifestyle experience.",
  "Located in the center of Meydan City, Meydan District One is a prominent residential development. It provides luxurious villas with incredible views of the waterfront and surrounded by lush greenery. Besides being situated close to Meydan Racecourse, residents have access to excellent amenities.",
  "Tilal Al Ghaf is a vibrant mixed-use community offering luxury villas, townhouses, and apartments surrounded by lush greenery and crystal-clear lagoons. It features a central lagoon, beach access, and a variety of leisure and recreational facilities.",
  "Dubai Land stands as a massive entertainment complex that combines theme parks with leisure facilities alongside large residential neighborhoods. It presents residents with a changing communal atmosphere that serves families together with individuals looking for different recreational options. The location puts residents within comfortable reach of major highways and distances to city attractions and landmarks. ",
  "Maritime City represents a waterfront development project that combines residential neighborhoods with business districts in addition to leisure amenities. The waterfront development Maritime City provides stunning ocean views together with maritime-focused facilities while offering prime access to Dubai's key destinations, which provide both work potential alongside recreational opportunities. Marine City attracts residents and professionals who seek its downtown ease while benefiting from oceanfront conveniences. ",
  "Motor City operates as a dynamic neighborhood located in the center of Dubai that attracts families with its welcoming environment. People who want comfort alongside convenient city access and urban park space will find Motor City offers a delightful residential environment. ",
  "Sharjah provides residents with both boutique cultural experiences and expansive lifestyle living while maintaining low noise levels in its urban environment. Homebuyers in this accessible residential neighborhood gain access to Dubai attractions at budget-friendly prices without giving up their expected peaceful family existence. ",
  "Sports fans find their perfect location in Dubai Sports City because its state-of-the-art sports infrastructure comes with an interactive community experience. The active population who likes sports will find their perfect community here since the complex offers different living styles along with various retail shopping spots and dining and recreational possibilities.",
  "Dubai Creek borders Al Jaddaf, which demonstrates its emerging status as a neighborhood combining contemporary residences with nearness to major city districts. The area continues to attract residents and businesses because it provides magnificent connectivity together with its central position. ",
  "Studio City stands as a creative center that combines high-end apartments with an energetic setting that includes media organizations together with restaurants along entertainment facilities. Media professionals choose this location while people who enjoy young dynamics select it as their perfect spot. ",
  "Innovation drives the development of Dubai South, while this project unites residential neighborhoods with commercial parks and recreational amenities. Professionals and families seeking connected family living with modern features should choose this area because of its prime location near the future business hubs and airports.",
  "The upscale property known as Dubai Healthcare City provides an elite environment for medical professionals together with people who want remarkable standards of living. The place provides medical professionals along with future residents a remarkable quality of life through its outstanding healthcare infrastructure, health-oriented residential development, and customized healthcare framework.",
  "The Valley provides its residents spacious accommodations among family-friendly surroundings that rest alongside abundant green spaces and recreational parks. The community provides residents with a relaxed suburban environment that blends seamlessly with urban connections.",
  "The residential neighborhood at Jebel Ali benefits from its position adjacent to Dubai's main port area while delivering both business connections and an environment that feels residential. Professionals and families alike find this neighborhood perfect because it combines spacious living accommodations and leisure amenities along with exceptional accessibility. ",
];

const CardPage = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleViewDetails = (index) => {
    setSelectedCard(index);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const links = [
    "/DubaiDowntown#top",
    "/DubaiMarina#top",
    "/JVT#top",
    "/BusinessBay#top",
    "/DH#top",
    "/ExpoCity#top",
    "/AlBarariCommunity#top",
    "/AlFurjan#top",
    "/PJ#top",
    "/DCH#top",
    "/DubaiIslands#top",
    "/DPC#top",
    "/EBF#top",
    "/MBR#top",
    "/MajidAlFuttaim#top",
    "/DL#top",
    "/DMC#top",
    "/MC#top",
    "/Spp#top",
    "/DSC#top",
    "/AJP#top",
    "/SCP#top",
    "/DSP#top",
    "/DHCC#top",
    "/TVP#top",
    "/JAP#top",
    null, // Ras Al Khaimah already has a link
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center mt-8 mb-10">
        <FirstCard image={Topc16} title="Ras Al Khaimah" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-14">
        {images.map((image, index) => (
          <div key={index} className="flex justify-center animate-fadeInUp">
            <Card
              image={image}
              title={titles[index]}
              onViewDetails={() => handleViewDetails(index)}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <LastCard
          image={Topc15}
          title="Jumeirah Village Circle"
          onViewDetails={() => handleViewDetails(images.length)}
        />
      </div>
      <div className="flex justify-center py-10">
        <Link
          to={"/contact#top"}
          type="button"
          className="text-white uppercase bg-[#D8B56D]  w-[20%]  font-medium rounded-3xl text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Contact
        </Link>
      </div>
      {selectedCard !== null && (
        <Popup
          image={selectedCard === images.length ? Topc15 : images[selectedCard]}
          title={
            selectedCard === images.length
              ? "Jumeirah Village Circle"
              : titles[selectedCard]
          }
          text={
            selectedCard === images.length
              ? "Jumeirah Village Circle is a family-oriented community offering a mix of villas, townhouses, and apartments. It features landscaped parks, jogging trails, and community centers. Residents enjoy a peaceful suburban lifestyle with easy access to amenities."
              : popupTexts[selectedCard]
          }
          link={links[selectedCard]}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};


export default CardPage;
