import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./Pages/About";
import WhyDubai from "./Pages/WhyDubai";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";
import HomePages from "./Pages/HomePages";
import OffPlanProperties from "./Pages/OffPlanProperties";
import Communities from "./Pages/Communities";
import TilalAlGhaf from "./DetailedPages/TilalAlGhaf";
import Zorah from "./ZorahDetails/Zorah";
import Blogs from "./Pages/Blogs";
import Page1 from "./BlogComponents/BlogDetails/Page1";
import Page2 from "./BlogComponents/BlogDetails/Page2";
import Page3 from "./BlogComponents/BlogDetails/Page3";
import Page4 from "./BlogComponents/BlogDetails/Page4";
import Page5 from "./BlogComponents/BlogDetails/Page5";
import Page6 from "./BlogComponents/BlogDetails/Page6";
import Page7 from "./BlogComponents/BlogDetails/Page7";
import Page8 from "./BlogComponents/BlogDetails/Page8";
import Page9 from "./BlogComponents/BlogDetails/Page9";
import Page10 from "./BlogComponents/BlogDetails/Page10";
import Page11 from "./BlogComponents/BlogDetails/Page11";
import Page12 from "./BlogComponents/BlogDetails/Page12";
import Page13 from "./BlogComponents/BlogDetails/Page13";
import Page14 from "./BlogComponents/BlogDetails/Page14";
import Developers from "./Developers/Developers";
import Projects from "./Projects/Projects";
import SobhaOne from "./Sobha Projects/SobhaOne";
import SobhaReserve from "./Sobha Projects/SobhaReserve";
import SobhaOrbis from "./Sobha Projects/SobhaOrbis";
import SobhaHeaven from "./Sobha Projects/SobhaSeaheaven";
import SobhaSeaheaven from "./Sobha Projects/SobhaSeaheaven";
import Green from "./Sobha Projects/Green";
import Riverside from "./Sobha Projects/Riverside";
import Waves from "./Sobha Projects/Waves";
import SkyscapeAvenue from "./Sobha Projects/SkyscapeAvenue";
import SkyscapeAura from "./Sobha Projects/SkyscapeAura";
import CoralBeach from "./Sobha Projects/CoralBeach";
import Verde from "./Sobha Projects/Verde";
import DamacProjectPage from "./Damac/DamacProjectPage";
import Altitude from "./Damac/DamacProjects/Altitude";
import Cavalli from "./Damac/DamacProjects/Cavalli";
import Autograph from "./Damac/DamacProjects/Autograph";
import ELO from "./Damac/DamacProjects/ELO";
import Casa from "./Damac/DamacProjects/Casa";
import ELO2 from "./Damac/DamacProjects/ELO2";
import GolfGate from "./Damac/DamacProjects/GolfGate";
import Harbourlights from "./Damac/DamacProjects/Harbourlights";
import Parkgreens from "./Damac/DamacProjects/Parkgreens";
import Violet from "./Damac/DamacProjects/Violet";
import Utopia from "./Damac/DamacProjects/Utopia";
import Verona from "./Damac/DamacProjects/Verona";
import Volta from "./Damac/DamacProjects/Volta";
import Morocco from "./Damac/DamacProjects/Morocco";
import AziziProjectPage from "./Azizi/AziziProjectPage";
import Adeba from "./Azizi/AziziProjects/Adeba";
import Amber from "./Azizi/AziziProjects/Amber";
import Beach from "./Azizi/AziziProjects/Beach";
import Neila from "./Azizi/AziziProjects/Neila";
import Venice from "./Azizi/AziziProjects/Venice";
import Creek from "./Azizi/AziziProjects/Creek";
import EmaarProjectPage from "./Emaar/EmaarProjectPage";
import Velora from "./Emaar/EmaarProjects/Velora";
import Seapoint from "./Emaar/EmaarProjects/Seapoint";
import Marina from "./Emaar/EmaarProjects/Marina";
import Avena from "./Emaar/EmaarProjects/Avena";
import Beachgate from "./Emaar/EmaarProjects/Beachgate";
import EllingtonProjectPage from "./Ellington/EllingtonProjectPage";
import Rosemont from "./Ellington/EllingtonProjects/Rosemont";
import River from "./Ellington/EllingtonProjects/River";
import Hillmont from "./Ellington/EllingtonProjects/Hillmont";
import Cala from "./Ellington/EllingtonProjects/Cala";
import Belgrove from "./Ellington/EllingtonProjects/Belgrove";
import TilalProjectPage from "./TilalAkGhaf/TilalProjectPage";
import Plagette from "./TilalAkGhaf/TilalProjects/Plagette";
import Elysian from "./TilalAkGhaf/TilalProjects/Elysian";
import Calia from "./TilalAkGhaf/TilalProjects/Calia";
import Amara from "./TilalAkGhaf/TilalProjects/Amara";
import Alaya from "./TilalAkGhaf/TilalProjects/Alaya";
import SamanaProjectPage from "./Samana/SamanaProjectPage";
import Portofino from "./Samana/SamanaProjects/Portofino";
import Manhattan2 from "./Samana/SamanaProjects/Manhattan2";
import Manhattan from "./Samana/SamanaProjects/Manhattan";
import Lake from "./Samana/SamanaProjects/Lake";
import Barari from "./Samana/SamanaProjects/Barari";
import AlbrariProjectPage from "./Albrari/AlbrariProjectPage";
import Show from "./Albrari/AlbrariProjects/Show";
import Altissima from "./Albrari/AlbrariProjects/Altissima";
import AldarProjectPage from "./Aldar/AldarProjectPage";
import DanubeProjectPage from "./Danube/DanubeProjectPage";
import Nikki from "./Aldar/AldarProjects/Nikki";
import Haven from "./Aldar/AldarProjects/Haven";
import Athlon from "./Aldar/AldarProjects/Athlon";
import Oceanz from "./Danube/DanubeProjects/Oceanz";
import Diamondz from "./Danube/DanubeProjects/Diamondz";
import Bayz101 from "./Danube/DanubeProjects/Bayz101";
import NakheelProjectPage from "./Nakheel/NakheelProjectPage";
import Rixos from "./Nakheel/NakheelProjects/Rixos";
import Como from "./Nakheel/NakheelProjects/Como";
import Bay from "./Nakheel/NakheelProjects/Bay";
import TigerProjectPage from "./Tiger/TigerProjectPage";
import Volga from "./Tiger/TigerProjects/Volga";
import Tower from "./Tiger/TigerProjects/Tower";
import Jade from "./Tiger/TigerProjects/Jade";
import MeraasProjectPage from "./Meraas/MeraasProjectPage";
import Acres from "./Meraas/MeraasProjects/Acres";
import Sheba from "./Meraas/MeraasProjects/Sheba";
import Jumeirah from "./Meraas/MeraasProjects/Jumeirah";
import OmniyatProjectPage from "./Omniyat/OmniyatProjectPage";
import Vela from "./Omniyat/OmniyatProjects/Vela";
import Mag from "./Omniyat/OmniyatProjects/Mag";
import MagProjectPage from "./Mag/MagProjectPage";
import Keturah from "./Mag/MagProjects/Keturah";
import S330 from "./Mag/MagProjects/S330";
import ExpoProjectPage from "./Expo/ExpoProjectPage";
import Yasmina from "./Expo/ExpoProjects/Yasmina";
import Sky from "./Expo/ExpoProjects/Sky";
import Maha from "./Expo/ExpoProjects/Maha";
import SelectProjectPage from "./Select/SelectProjectPage";
import Nautica from "./Select/SelectProjects/Nautica";
import JumeirahB from "./Select/SelectProjects/JumeirahB";
import BinghattiProjectPage from "./Binghatti/BinghattiProjectPage";
import OneBinghatti from "./Binghatti/BinghattiProjects/OneBinghatti";
import BRoyale from "./Binghatti/BinghattiProjects/BRoyale";
import Ghost from "./Binghatti/BinghattiProjects/Ghost";
import WaslProjectPage from "./Wasl/WaslProjectPage";
import Hillside from "./Wasl/WaslProjects/Hillside";
import Onetower from "./Wasl/WaslProjects/Onetower";
import Wtower from "./Wasl/WaslProjects/Wtower";
import AlhabtoorProjectPage from "./Alhabtoor/AlhabtoorProjectPage";
import Htower from "./Alhabtoor/AlhabtoorProjects/Htower";
import Hgrand from "./Alhabtoor/AlhabtoorProjects/Hgrand";
import Ahtower from "./Alhabtoor/AlhabtoorProjects/Ahtower";
import SevenProjectPage from "./Seven/SevenProjectPage";
import Bridge from "./Seven/SevenProjects/Bridge";
import Minor from "./Seven/SevenProjects/Minor";
import UnionProjectPage from "./Union/UnionProjectPage";
import Takaya from "./Union/UnionProjects/Takaya";
import Logo from "./Images/Logo.png"
import DownTown from "./DowntownDetails/Downtown";
import Boulevard from "./BoulevardDetails/Boulevard";
import CBO from "./CreekDetails/CBO";
import Maison from "./MaisonDetails/Maison";
import Opera from "./Opera/Opera";
import Bayz102 from "./Danube/DanubeProjects/Bayz102";
import AradaProjectPage from "./Arada/AradaProjectPage";
import WR from "./Arada/AradaProjects/WR";
import BNWProjectPage from "./BNW/BNWProjectPage";
import Aqua from "./BNW/BNWProjects/Aqua";
import LuxeProjectPage from "./Luxe/LuxeProjectPage";
import Mazzoni from "./Luxe/LuxeProjects/Mazzoni";
import Rental from "./Rental/RentalHome";
import Arno from "./Rental/ArnoDetails/Arno";
import Burj from "./Rental/BurjDetials/Burj";
import Pearl from "./Rental/PearlDetails/Pearl";
import Armani from "./Arada/AradaProjects/Armani";
import Gem from "./Rental/GemDetails/Gem";
import RakProjectPage from "./Rak/RakProjectPage";
import Gems from "./Rental/GemsDetails/Gems";
import Anantara from "./Arada/AradaProjects/Anantara";
import Murooj from "./Rental/MuroojDetails/Murooj";
import Lakes from "./Rental/LakeDetails/Lakes";
import Warqa from "./Rental/WarqaDetails/Warqa";
import Sls from "./Rental/SlsDetails/Sls";
import Object1ProjectPage from "./Object1/Object1ProjectPage";
import ESSENL1FE from "./Object1/Object1Projects/ESSENL1FE";
import TETR1S from "./Object1/Object1Projects/TETR1S";
import V1STARA from "./Object1/Object1Projects/V1STARA";
import PantheonProjectPage from "./Pantheon/PantheonProjectPage";
import Central from "./Pantheon/PantheonProjects/Central";
import Elysee from "./Pantheon/PantheonProjects/Elysee";
import ImtiazProjectPage from "./Imtiaz/ImtiazProjectPage";
import Cotier from "./Imtiaz/ImtiazProjects/Cotier";
import Resorts from "./Samana/SamanaProjects/Resorts";
import Gviews from "./Samana/SamanaProjects/Gviews";
import Ivy from "./Samana/SamanaProjects/Ivy";
import BusinessBayProjectPage from "./BusinessBay/BusinessBayProjectPage";
import DowntownProjectPage from "./Downtown/DowntownProjectPage";
import MarinaProjectPage from "./DubaiMarina/MarinaProjectPage";
import MajidAlFuttaimProjectPage from "./MajidAlFuttaim/MajidAlFuttaimProjectPage";
import JVCProjectPage from "./JVC/JVCProjectPage";
import EmaarBeachfrontProjectPage from "./EmaarBeachfront/EmaarBeachfrontProjectPage";
import PalmJumeirahProjectPage from "./PalmJumeirah/PalmJumeirahProjectPage";
import MBRCityProjectPage from "./MBRCity/MBRCityProjectPage";
import DubaiHarbourProjectPage from "./DubaiHarbour/DubaiHarbourProjectPage";
import DamacHillsProjectPage from "./DamacHills/DamacHillsProjectPage";
import JVTProjectPage from "./JVT/JVTProjectPage";
import ExpoCityProjectPage from "./ExpoCity/ExpoCityProjectPage";
import AlBarariCommunityProjectPage from "./AlBarariCommunity/AlBarariCommunityProjectPage";
import AlFurjanProjectPage from "./AlFurjan/AlFurjanProjectPage";
import DubaiIslandsProjectPage from "./DubaiIslands/DubaiIslandsProjectPage";
import ProductionCityProjectPage from "./ProductionCity/ProductionCityProjectPage";
import DubaiLandProjectPage from "./DubaiLand/DubaiLandProjectPage";
import MaritimeCityProjectPage from "./MaritimeCity/MaritimeCityProjectPage";
import MotorcityProjectPage from "./Motorcity/MotorcityProjectPage";
import SharjahProjectPage from "./Sharjah/SharjahProjectPage";
import DubaiSportsCityProjectPage from "./DubaiSportsCity/DubaiSportsCityProjectPage";
import AlJaddafProjectPage from "./AlJaddaf/AlJaddafProjectPage";
import StudiocityProjectPage from "./Studiocity/StudiocityProjectPage";
import DubaiSouthProjectPage from "./DubaiSouth/DubaiSouthProjectPage";
import DubaihealthCareCityProjectPage from "./DubaihealthCareCity/DubaihealthCareCityProjectPage";
import TheValleyProjectPage from "./TheValley/TheValleyProjectPage";
import JabelAliProjectPage from "./JabelAli/JabelAliProjectPage";
import Pelagia from "./BNW/BNWProjects/Pelagia";
function App() {
  return (
    // <div>
    //   <h1>CI/CD ERROR:500 Please Check CI/CD Pipeline</h1>
    // </div>
    <div>
    
  <BrowserRouter>
 
    <Routes>
      
      <Route path="/" element={<HomePages />} />
      <Route path="/whydubai" element={<WhyDubai />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/offplan" element={<OffPlanProperties />} />
      <Route path="/rental" element={<Rental />} />
      <Route path="/communities" element={<Communities />} />
      <Route path="/tilalAlGhaf" element={<TilalAlGhaf />} />
      <Route path="/zorah" element={<Zorah />} />
      <Route path="/DownTown" element={<DownTown />} />
      <Route path="/Boulevard" element={<Boulevard />} />
      <Route path="/CBO" element={<CBO />} />
      <Route path="/Opera" element={<Opera />} />
      <Route path="/Maison" element={<Maison />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogdetail1" element={<Page1 />} />
      <Route path="/blogdetail2" element={<Page2 />} />
      <Route path="/blogdetail3" element={<Page3 />} />
      <Route path="/blogdetail4" element={<Page4 />} />
      <Route path="/blogdetail5" element={<Page5 />} />
      <Route path="/blogdetail6" element={<Page6 />} />
      <Route path="/blogdetail7" element={<Page7 />} />
      <Route path="/blogdetail8" element={<Page8 />} />
      <Route path="/blogdetail9" element={<Page9 />} />
      <Route path="/blogdetail10" element={<Page10 />} />
      <Route path="/blogdetail11" element={<Page11 />} />
      <Route path="/blogdetail12" element={<Page12 />} />
      <Route path="/blogdetail13" element={<Page13 />} />
      <Route path="/blogdetail14" element={<Page14 />} />
      <Route path="/developers" element={<Developers />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/sobhaone" element={<SobhaOne />} />
      <Route path="/SobhaReserve" element={<SobhaReserve />} />
      <Route path="/SobhaOrbis" element={<SobhaOrbis />} />
      <Route path="/SobhaHeaven" element={<SobhaSeaheaven />} />
      <Route path="/Green" element={<Green />} />
      <Route path="/Riverside" element={<Riverside />} />
      <Route path="/Waves" element={<Waves />} />
      <Route path="/SkyscapeAvenue" element={<SkyscapeAvenue />} />
      <Route path="/SkyscapeAura" element={<SkyscapeAura />} />
      <Route path="/CoralBeach" element={<CoralBeach />} />
      <Route path="/Verde" element={<Verde />} />
      <Route path="/Damac" element={<DamacProjectPage />} />
      <Route path="/Altitude" element={<Altitude />} />
      <Route path="/Autograph" element={<Autograph />} />
      <Route path="/Cavalli" element={<Cavalli />} />
      <Route path="/ELO" element={<ELO />} />
      <Route path="/ELO2" element={<ELO2 />} />
      <Route path="/GolfGate" element={<GolfGate />} />
      <Route path="/Casa" element={<Casa />} />
      <Route path="/Harbourlights" element={<Harbourlights />} />
      <Route path="/Parkgreens" element={<Parkgreens />} />
      <Route path="/Violet" element={<Violet />} />
      <Route path="/Utopia" element={<Utopia />} />
      <Route path="/Verona" element={<Verona />} />
      <Route path="/Volta" element={<Volta />} />
      <Route path="/Morocco" element={<Morocco />} />
      <Route path="/Azizi" element={<AziziProjectPage />} />
      <Route path="/Adeba" element={<Adeba />} />
      <Route path="/Amber" element={<Amber />} />
      <Route path="/Beach" element={<Beach />} />
      <Route path="/Neila" element={<Neila />} />
      <Route path="/Venice" element={<Venice />} />
      <Route path="/Creek" element={<Creek />} />
      <Route path="/Emaar" element={<EmaarProjectPage />} />
      <Route path="/Velora" element={<Velora />} />
      <Route path="/Seapoint" element={<Seapoint />} />
      <Route path="/Marina" element={<Marina />} />
      <Route path="/Avena" element={<Avena />} />
      <Route path="/Beachgate" element={<Beachgate />} />
      <Route path="/Ellington" element={<EllingtonProjectPage />} />
      <Route path="/Rosemont" element={<Rosemont />} />
      <Route path="/River" element={<River />} />
      <Route path="/Hillmont" element={<Hillmont />} />
      <Route path="/Cala" element={<Cala />} />
      <Route path="/Belgrove" element={<Belgrove />} />
      <Route path="/Tilal" element={<TilalProjectPage />} />
      <Route path="/Plagette" element={<Plagette />} />
      <Route path="/Elysian" element={<Elysian />} />
      <Route path="/Calia" element={<Calia />} />
      <Route path="/Amara" element={<Amara />} />
      <Route path="/Alaya" element={<Alaya />} />
      <Route path="/Samana" element={<SamanaProjectPage />} />
      <Route path="/Portofino" element={<Portofino />} />
      <Route path="/Manhattan2" element={<Manhattan2 />} />
      <Route path="/Manhattan" element={<Manhattan />} />
      <Route path="/Lake" element={<Lake />} />
      <Route path="/Barari" element={<Barari />} />
      <Route path="/Albrari" element={<AlbrariProjectPage />} />
      <Route path="/Show" element={<Show />} />
      <Route path="/Altissima" element={<Altissima />} />
      <Route path="/Aldar" element={<AldarProjectPage />} />
      <Route path="/Nikki" element={<Nikki />} />
      <Route path="/Haven" element={<Haven />} />
      <Route path="/Athlon" element={<Athlon />} />
      <Route path="/Danube" element={<DanubeProjectPage />} />
      <Route path="/Oceanz" element={<Oceanz />} />
      <Route path="/Diamondz" element={<Diamondz />} />
      <Route path="/Bayz101" element={<Bayz101 />} />
      <Route path="/Bayz102" element={<Bayz102 />} />
      <Route path="/Nakheel" element={<NakheelProjectPage />} />
      <Route path="/Rixos" element={<Rixos />} />
      <Route path="/Como" element={<Como />} />
      <Route path="/Bay" element={<Bay />} />
      <Route path="/Tiger" element={<TigerProjectPage />} />
      <Route path="/Volga" element={<Volga />} />
      <Route path="/Tower" element={<Tower />} />
      <Route path="/Jade" element={<Jade />} />
      <Route path="/Meraas" element={<MeraasProjectPage />} />
      <Route path="/Acres" element={<Acres />} />
      <Route path="/Sheba" element={<Sheba />} />
      <Route path="/Jumeirah" element={<Jumeirah />} />
      <Route path="/Omniyat" element={<OmniyatProjectPage />} />
      <Route path="/Vela" element={<Vela />} />
      <Route path="/Mag" element={<Mag />} />
      <Route path="/Mag" element={<Mag />} />
      <Route path="/Mags" element={<MagProjectPage />} />
      <Route path="/Keturah" element={<Keturah />} />
      <Route path="/S330" element={<S330 />} />
      <Route path="/Expo" element={<ExpoProjectPage />} />
      <Route path="/Yasmina" element={<Yasmina />} />
      <Route path="/Sky" element={<Sky />} />
      <Route path="/Maha" element={<Maha />} />
      <Route path="/Select" element={<SelectProjectPage />} />
      <Route path="/Nautica" element={<Nautica />} />
      <Route path="/JumeirahB" element={<JumeirahB />} />
      <Route path="/Binghatti" element={<BinghattiProjectPage />} />
      <Route path="/OneBinghatti" element={<OneBinghatti />} />
      <Route path="/BRoyale" element={<BRoyale />} />
      <Route path="/Ghost" element={<Ghost />} />
      <Route path="/Wasl" element={<WaslProjectPage />} />
      <Route path="/Hillside" element={<Hillside />} />
      <Route path="/Onetower" element={<Onetower />} />
      <Route path="/Wtower" element={<Wtower />} />
      <Route path="/Alhabtoor" element={<AlhabtoorProjectPage />} />
      <Route path="/Htower" element={<Htower />} />
      <Route path="/Hgrand" element={<Hgrand />} />
      <Route path="/Ahtower" element={<Ahtower />} />
      <Route path="/Seven" element={<SevenProjectPage />} />
      <Route path="/Bridge" element={<Bridge />} />
      <Route path="/Minor" element={<Minor />} />
      <Route path="/Union" element={<UnionProjectPage />} />
      <Route path="/Takaya" element={<Takaya />} />
      <Route path="/Arada" element={<AradaProjectPage />} />
      <Route path="/BNW" element={<BNWProjectPage />} />
      <Route path="/WR" element={<WR />} />
      <Route path="/Aqua" element={<Aqua />} />
      <Route path="/Luxe" element={<LuxeProjectPage />} />
      <Route path="/Mazzoni" element={<Mazzoni />} />
      <Route path="/Arno" element={<Arno />} />
      <Route path="/Burj" element={<Burj />} />
      <Route path="/Pearl" element={<Pearl />} />
      <Route path="/Armani" element={<Armani />} />
      <Route path="/Gem" element={<Gem />} />
      <Route path="/Gems" element={<Gems />} />
      <Route path="/RAK" element={<RakProjectPage />} />
      <Route path="/Anantara" element={<Anantara />} />
      <Route path="/Murooj" element={<Murooj />} />
      <Route path="/Lakes" element={<Lakes />} />
      <Route path="/Warqa" element={<Warqa />} />
      <Route path="/Sls" element={<Sls />} />
      <Route path="/Object1" element={<Object1ProjectPage />} />
      <Route path="/ESSENL1FE" element={<ESSENL1FE />} />
      <Route path="/TETR1S" element={<TETR1S />} />
      <Route path="/V1STARA" element={<V1STARA />} />
      <Route path="/Pantheon" element={<PantheonProjectPage />} />
      <Route path="/Central" element={<Central />} />
      <Route path="/Elysee" element={<Elysee />} />
      <Route path="/Imtiaz" element={<ImtiazProjectPage />} />
      <Route path="/Cotier" element={<Cotier />} />
      <Route path="/Resorts" element={<Resorts />} />
      <Route path="/Gviews" element={<Gviews />} />
      <Route path="/Ivy" element={<Ivy />} />
      <Route path="/BusinessBay" element={<BusinessBayProjectPage />} />
      <Route path="/DubaiDowntown" element={<DowntownProjectPage />} />
      <Route path="/DubaiMarina" element={<MarinaProjectPage />} />
      <Route path="/JVT" element={<JVTProjectPage />} />
      <Route path="/MajidAlFuttaim" element={<MajidAlFuttaimProjectPage />} />
      <Route path="/JVC" element={<JVCProjectPage />} />
      <Route path="/EBF" element={<EmaarBeachfrontProjectPage />} />
      <Route path="/PJ" element={<PalmJumeirahProjectPage />} />
      <Route path="/MBR" element={<MBRCityProjectPage />} />
      <Route path="/DCH" element={<DubaiHarbourProjectPage />} />
      <Route path="/DH" element={<DamacHillsProjectPage />} />
      <Route path="/ExpoCity" element={<ExpoCityProjectPage />} />
      <Route path="/AlBarariCommunity" element={<AlBarariCommunityProjectPage />} />
      <Route path="/AlFurjan" element={<AlFurjanProjectPage />} />
      <Route path="/DubaiIslands" element={<DubaiIslandsProjectPage />} />
      <Route path="/DPC" element={<ProductionCityProjectPage />} />
      <Route path="/DL" element={<DubaiLandProjectPage />} />
      <Route path="/DMC" element={<MaritimeCityProjectPage />} />
      <Route path="/MC" element={<MotorcityProjectPage />} />
      <Route path="/Spp" element={<SharjahProjectPage />} />
      <Route path="/DSC" element={<DubaiSportsCityProjectPage />} />
      <Route path="/AJP" element={<AlJaddafProjectPage />} />
      <Route path="/SCP" element={<StudiocityProjectPage />} />
      <Route path="/DSP" element={<DubaiSouthProjectPage />} />
      <Route path="/DHCC" element={<DubaihealthCareCityProjectPage />} />
      <Route path="/TVP" element={<TheValleyProjectPage />} />
      <Route path="/JAP" element={<JabelAliProjectPage />} />
      <Route path="/Pelagia" element={<Pelagia />} />

    </Routes>
  </BrowserRouter>
    </div>
);
}

export default App;
